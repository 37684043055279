@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
}

// container config
@media (min-width: 1024px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 430px) {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 375px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// font face config
@font-face {
  font-family: "SVN-Gotham-Regular";
  src: url("../public/fonts/SVN-Gotham Regular.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gotham-Bold";
  src: url("../public/fonts/SVN-Gotham Bold.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gotham-Book";
  src: url("../public/fonts/SVN-Gotham Book.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gotham-Book-Italic";
  src: url("../public/fonts/SVN-Gotham Book Italic.otf") format("opentype");
}

@font-face {
  font-family: "SVN-Gotham-Light";
  src: url("../public/fonts/SVN-Gotham Light.otf") format("opentype");
}

.font-gotham-regular {
  font-family: "SVN-Gotham-Regular";
}

.font-gotham-bold {
  font-family: "SVN-Gotham-Bold";
}

.font-gotham-book {
  font-family: "SVN-Gotham-Book";
}

.font-gotham-italic {
  font-family: "SVN-Gotham-Italic";
}

.font-gotham-light {
  font-family: "SVN-Gotham-Light";
}
.w-inherit {
  width: inherit;
}
.w-webkit {
  width: -webkit-fill-available;
}
.search-container {
  width: clamp(500px, 37vw, 620px);
  height: clamp(60px, 4vw, 62px);
}

// loading
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #472f92;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.react-photo-album--row {
  img {
    object-fit: cover;
  }
}

/* input send cruitment */
.formSubmit {
  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  @media (max-width: 425px) {
    input {
      display: block;
      box-sizing: border-box;
      width: 320px;
      border-radius: 4px;
      padding: 5px 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

/* swiper Person */
.swiperPerson {
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    position: unset !important;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    margin: auto;
    // transition:all 200ms linear;
    transform: scale(0.95) !important;
  }
  .swiper-slide-next {
    transform: scale(1.08) !important;
  }

  .swiper-slide-next .each-slide {
    background-color: #dcd7e9;
    // min-height: 950px;
  }

  .swiper-slide .each-slide {
    // min-height: 850px;
  }
}

// swiper person mobile
.swiperPersonMobile {
  .swiper-button-next {
    background-size: 50% auto;
  }
  .swiper-button-prev {
    background-size: 50% auto;
  }
}
.peopleEdutalk {
  // swiper config common
  .swiper-button-next {
    background-image: url(../public/images/next.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  .swiper-button-next::after {
    display: none;
  }
  .swiper-button-prev {
    background-image: url(../public/images/previous.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
  .swiper-button-prev::after {
    display: none;
  }
}
#introJobDesktop .swiper-pagination-bullet-active {
  background: #513690;
}

.yarl__slide_image {
  width: -webkit-fill-available;
}

.h-revert-layer {
  height: revert-layer;
}
li::marker {
  font-size: 0.65em;
}

/* Hide the default file input text */
input[type="file"] {
  color: transparent;
}

/* Show custom placeholder text */
input[type="file"]::before {
  content: "";
}
.video-frame iframe {
  width: 100% !important;
  height: 100% !important;
}
