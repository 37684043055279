  .button-pag {
    width: 10px;
    height: 10px;
    float: left;
    margin-right: 20px;
    background-color: #BEBEBE;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease width;
  }
  
  .button-pag:last-child {
    margin-right: 0;
  }
  
  .button-pag.active {
    background-color: #513690;
    // width: 60px;
    cursor: auto;
  }
  