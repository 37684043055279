.deco {
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
}

@media screen and (min-width: 1024px) {
    .deco {
        width: 276px;
    }
}




